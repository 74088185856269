
// setting variables.
:root {
  --cs-primary: #3699ff;
  --cs-primary-bg: #eaf2f5;

  --cs-secondary: rgba(45, 87, 106, 1);
  --cs-secondary-lighter: rgba(76, 152, 187, 1);

  --cs-menu-bg: #eaeef3;
  --cs-submenu-bg: #eaeef3;
  --cs-submenu-bg-hover: #f3f6f9;
}

a,
.text-primary {
  color: var(--cs-primary) !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: var(--cs-primary) !important;
  opacity: 0.75;
}

//// ==== START: STEPPER ====
._setup ._stepper {
  background-color: var(--cs-primary-bg) !important;
}
._setup ._stepper ._no {
  background-color: var(--cs-submenu-bg) !important;
}
._setup ._stepper.active {
  background-color: var(--cs-primary) !important;
}
._setup ._stepper.done,
._setup ._stepper.done ._no {
  color: var(--cs-primary) !important;
}
//// ==== END: STEPPER ====

//// ==== END: BUTTONS ====
.btn.btn-primary {
  color: #ffffff;
  background-color: var(--cs-primary) !important;
  border-color: var(--cs-primary) !important;
  transition: 0.3s all !important;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  opacity: 0.75;
  transition: 0.3s all !important;
}
.btn-primary:focus,
.btn-primary.focus {
  opacity: 0.8;
  transition: 0.3s all !important;
}
.btn.btn-outline-primary {
  color: var(--cs-primary) !important;
  border-color: var(--cs-primary) !important;
  transition: 0.3s all !important;
}
.btn.btn-outline-primary .svg-icon svg g [fill] {
  fill: var(--cs-primary) !important;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-outline-primary:not(:disabled):not(.disabled).active
  .svg-icon
  svg
  g
  [fill],
.show > .btn.btn-outline-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-primary.btn-dropdown .svg-icon svg g [fill],
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  fill: #ffffff !important;
}
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-outline-primary:focus:not(.btn-text),
.btn.btn-outline-primary.focus:not(.btn-text),
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-primary.dropdown-toggle,
.show .btn.btn-outline-primary.btn-dropdown {
  color: #ffffff !important;
  background-color: var(--cs-primary) !important;
  border-color: var(--cs-primary) !important;
  transition: 0.3s all !important;
  //   opacity: 0.75;
}
.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  background-color: var(--cs-primary) !important;
  border-color: var(--cs-primary) !important;
  transition: 0.3s all !important;
}
.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: var(--cs-primary) !important;
  border-color: var(--cs-primary) !important;
  opacity: 0.8;
  transition: 0.3s all !important;
}
//// ==== END: BUTTONS ====

//// ==== START: ASIDE NAVIGATION MENU ====
.svg-icon.svg-icon-primary svg g [fill] {
  fill: var(--cs-primary) !important;
}
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  fill: var(--cs-primary) !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: var(--cs-primary) !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: var(--cs-primary) !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: var(--cs-menu-bg) !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: var(--cs-submenu-bg-hover) !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: var(--cs-submenu-bg) !important;
}

.navi.navi-hover .navi-item .navi-link:hover,
.navi.navi-active .navi-item .navi-link.active {
  background-color: var(--cs-submenu-bg) !important;
}
.navi .navi-item .navi-link:hover .navi-text,
.navi .navi-item .navi-link.active .navi-text {
  color: var(--cs-primary) !important;
}
.navi .navi-item .navi-link:hover .navi-icon svg g [fill],
.navi .navi-item .navi-link.active .navi-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: var(--cs-primary) !important;
}
//// ==== END: ASIDE NAVIGATION MENU ====

.MuiRadio-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--cs-primary) !important;
}

.react-bootstrap-table .table.table-head-custom thead tr .sortable-active {
  color: var(--cs-primary) !important;
}
.fum-bg {
  background: var(--cs-secondary);
  background: radial-gradient(
    circle,
    var(--cs-secondary-lighter) 0%,
    var(--cs-secondary) 100%
  );
}
.cs-stepper {
  --step-bar-bg: var(--cs-primary);
}
.cs-stepper__input:checked + .cs-stepper__step .cs-stepper__button::before {
  box-shadow: 0 0 0 4px var(--cs-primary-bg);
}
.cs-stepper__input:checked + .cs-stepper__step {
  --step-bullet-bg: var(--cs-primary);
}
.checkbox > input:checked ~ span {
  background-color: var(--cs-primary);
}
.splash-spinner .path {
  stroke: var(--cs-primary);
}
.breadcrumb .breadcrumb-item.active i,
.breadcrumb .breadcrumb-item.active a,
.breadcrumb .breadcrumb-item:hover i,
.breadcrumb .breadcrumb-item:hover a {
  color: var(--cs-primary) !important;
}
.bg-primary {
  background-color: var(--cs-primary) !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--cs-primary) !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: var(--cs-primary) !important;
}
.spinner.spinner-primary:before {
  border: 2px solid var(--cs-primary) !important;
  border-right: 2px solid transparent !important;
}
.MuiCircularProgress-colorPrimary {
  color: var(--cs-primary) !important;
}
.radio > input:checked ~ span {
  background-color: var(--cs-primary) !important;
}
.badge-primary {
  color: #ffffff;
  background-color: var(--cs-primary) !important;
}
