// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_chamasoft/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_chamasoft/_assets/sass/themes/layout/header/base/light.scss";
@import "./_chamasoft/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_chamasoft/_assets/sass/themes/layout/brand/dark.scss";
@import "./_chamasoft/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_chamasoft/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_chamasoft/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_chamasoft/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_chamasoft/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_chamasoft/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_chamasoft/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_chamasoft/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_chamasoft/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
body {
  background: #f5f7fb !important;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.cs-stepper {
  --s-cs-stepper-bullet: 3rem;
  --s-cs-stepper-bullet-half: calc(var(--s-cs-stepper-bullet) / 2);
  --step-transition: background 0.5s, color 0.5s;
  --step-content: "✔︎";
  --step-color: hsl(0, 0%, 70%);
  --step-bar-bg: #00aaf1;
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: white;
  counter-reset: current-step;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}
.cs-stepper__input {
  counter-increment: steps;
  display: none;
}
.cs-stepper__step {
  counter-increment: current-step;
}
.cs-stepper__input:checked ~ .cs-stepper__step {
  --step-color: #d5d7db;
  --step-bar-bg: #d5d7db;
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: #d5d7db;
  --step-content: counter(current-step);
}
.cs-stepper__input:checked + .cs-stepper__step {
  --step-bullet-bg: #00aaf1;
  --step-bullet-color: white;
  --step-color: white;
}
.cs-stepper__input:checked + .cs-stepper__step .cs-stepper__button::before {
  box-shadow: 0 0 0 4px #3ec5ff;
}
.cs-stepper__button {
  position: relative;
  text-align: center;
  color: var(--step-bullet-bg);
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  // line-height: 1;
  //   cursor: pointer;
}
.cs-stepper__button::before {
  content: var(--step-content);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 auto var(--s-cs-stepper-bullet-half);
  height: var(--s-cs-stepper-bullet);
  width: var(--s-cs-stepper-bullet);
  border-radius: var(--s-cs-stepper-bullet);
  -webkit-transition: var(--step-transition);
  transition: var(--step-transition);
  background: var(--step-bullet-bg);
  color: white;
}
.cs-stepper__button::after {
  content: "";
  position: absolute;
  width: 72%;
  height: calc(var(--s-cs-stepper-bullet-half) / 2);
  background: var(--step-bar-bg);
  transition: var(--step-transition);
  top: var(--s-cs-stepper-bullet-half);
  left: 64%;
  transform: translate(0, -50%);
  z-index: -1;
  border-radius: 2rem;
}
.cs-stepper__step:first-child .cs-stepper__button::after {
  display: none;
}
.cs-stepper__step:last-child .cs-stepper__button::after {
  display: none;
}
.cs-stepper--flexbox {
  display: -webkit-box;
  display: flex;
}
.cs-stepper--flexbox .cs-stepper__step {
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.MuiFormControl-marginNormal {
  margin-bottom: 0px !important;
}
.fv-plugins-message-container {
  position: absolute !important;
}

.bw-2 {
  border-width: 2px !important;
}

.cs-checkbox.checkbox > span {
  border: 1px solid #ccc;
}

.cs-radio.radio > span {
  border: 1px solid #ccc;
}

.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-0 {
  opacity: 0;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link.onboard {
  background-color: #fff;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  border: 3px dashed rgba(0, 0, 0, 0.15);
  border-radius: 0.42rem;
  transition: 0.2s;
  &.is-active {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.42rem;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}
.file-drop-area-progress {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 25px;
  border-radius: 0.42rem;
  transition: 0.2s;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  z-index: 1;
  left: 0;
}

.cs-phone-inp,
.cs-phone-inp .MuiInputBase-root.MuiInputBase-formControl {
  width: 100%;
}

span svg {
  pointer-events: none !important;
}

.txt-trunc {
  // width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overlay-tint {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 44px;
  height: 60px;
  background: rgb(243, 246, 249);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
  display: block;
}

._setup ._stepper {
  display: block;
  background-color: #d2dbe452;
  padding: 4px;
  border-radius: 120px;
  cursor: default;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}
._setup ._stepper ._no,
._setup ._stepper ._label {
  display: inline-block;
}
// ._setup ._stepper ._label {
//   margin-top: 7px;
//   position: absolute;
// }
._setup ._stepper ._no {
  width: 36px;
  height: 36px;
  background-color: #d9e5f1;
  border-radius: 120px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
}
._setup ._stepper ._no span {
  margin-top: 7px;
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}
._setup ._stepper ._label span {
  // margin-top: 10px;
  margin-left: 12px;
  display: block;
  text-align: center;
  font-size: 15px;
}
// ._setup ._stepper:hover {
//   opacity: 0.7;
//   transition: all 0.3s ease;
// }
._setup ._stepper.active {
  color: #ffffff;
}
._setup ._stepper.active ._no {
  background-color: #ffffff52;
}
._setup ._stepper.done {
  font-weight: 500;
  background-color: #abd0f175;
}
._setup ._stepper.done ._no {
  font-weight: 900;
}
._setup ._stepper.done ._label span::after {
  position: absolute;

  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  right: 30px;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  margin-top: -3px;
}
.MuiFormControl-marginNormal {
  margin-top: 0 !important;
}

.custom-card-body {
  padding: 1rem 2.25rem !important;
}

table tr:last-child td.MuiTableCell-root {
  border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.05) !important;
}
.dropdown-menu {
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.15);
}
.dropdown-br button {
  border-radius: 50px;
  padding: 6px 20px;
}
.excel-download {
  padding: 12px 32px 14px;
  border-radius: 3px;
  cursor: pointer;
  color: #2e7d32 !important;
  background-size: 32px !important;
  background-position-x: 20px !important;
  padding-left: 60px;
  margin-bottom: 20px;
  border: none;
}
.excel-download:hover {
  opacity: 0.8;
  border: none;
}
.alert.alert-custom.alert-light-warning {
  border: 1px solid #ffa80040;
}
.alert.alert-custom.alert-light-danger {
  border: 1px solid #f64e6040;
}
.alert.alert-custom.alert-light-success {
  border: 1px solid #1bc5bd40;
}
.alert.alert-custom.alert-light-info {
  border: 1px solid #8950fc40;
}
.form-array-scroller {
  max-height: 520px;
  overflow-y: auto;
  width: 100%;
  margin: 20px 0;
}
.cursor-default {
  cursor: default;
}

.steps{
  padding-left:20px;
  list-style: none;
}
.steps .step {
  border: 0;
  border-left: 1px solid #d8d8d8;
  box-sizing: border-box;
  counter-increment: step-counter;
  padding: 0 0 40px 35px;
}
.steps .step.active {
  background-color:transparent;
  color:inherit;
}
.steps .step:last-child{
  border-left: 1px solid transparent;
  padding-bottom: 0;
}
.steps .step::before {
  border-radius: 50%;
  border: 2px solid #fff;
  // background-color:#16c477;
  color:#fff;
  content: counter(step-counter);
  font-size: 16px;
  // display:inline-block;
  float:left;
  height: 34px;
  line-height: 30px;
  margin-left:-52px;
  text-align:center;  
  width:34px;  
}
.steps.websacco .step::before { background-color:#16c477; }
.steps.chamasoft .step::before { background-color:#00abf2; }

.steps.websacco .step span strong { color:#16c477!important; }
.steps.chamasoft .step span strong { color:#00abf2!important; }

.steps .step span {
  display: block;
  padding-top: 7px;
}

.s_icn{
  cursor: pointer;
}
.s_icn:hover{
  opacity:0.4;
}
.s_icn.fb{ color:#3B5999; }
.s_icn.tw{ color:#08A0E9; }
.s_icn.li{ color:#003A58; }
.s_icn.po{ color:#EC4153; }
.s_icn.cp{ columns: #196F28; }
.s_icn.ma{ columns: #333333; }
.s_icn.wa{ color:rgb(12, 160, 36); }